import React, { useState, useRef, useEffect } from 'react';
import { Loader, Code, Eye, Camera, Upload } from 'lucide-react';
import BPMNPreview from './BPMNPreview';

const BPMNGenerator = () => {
  const [input, setInput] = useState('');
  const [imageUrl, setImageUrl] = useState('');
  const [bpmnOutput, setBpmnOutput] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showCalmPrompt, setShowCalmPrompt] = useState(false);
  const [diagramName, setDiagramName] = useState('');
  const [status, setStatus] = useState('');
  const [viewMode, setViewMode] = useState('xml');
  const [showCamera, setShowCamera] = useState(false);
  const [cameraInitialized, setCameraInitialized] = useState(false);

  const fileInputRef = useRef(null);
  const videoRef = useRef(null);
  const streamRef = useRef(null);

  const [isCameraSupported, setIsCameraSupported] = useState(true);
  const [cameraError, setCameraError] = useState(null);

  const [videoAspectRatio, setVideoAspectRatio] = useState(4/3); // default aspect ratio

  useEffect(() => {
    return () => {
          // Check if getUserMedia is supported
    if (!navigator.mediaDevices?.getUserMedia) {
      setIsCameraSupported(false);
      setCameraError('Camera access is not supported in this browser');
    }
      if (streamRef.current) {
        streamRef.current.getTracks().forEach(track => track.stop());
        streamRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (videoRef.current && videoRef.current.videoWidth) {
      setVideoAspectRatio(videoRef.current.videoWidth / videoRef.current.videoHeight);
    }
  }, [cameraInitialized]);

  const handleImageUrlInput = (e) => {
    setImageUrl(e.target.value);
    setInput('');
    setError('');
  };

  const handleTextInput = (e) => {
    setInput(e.target.value);
    setImageUrl('');
    setError('');
  };

  const uploadToBlob = async (file) => {
    try {
      setStatus('Getting upload URL...');
      setLoading(true);
      setError('');
      
      const urlResponse = await fetch('/api/get-blob-sas-url');
      if (!urlResponse.ok) {
        throw new Error(`Failed to get upload URL: ${urlResponse.statusText}`);
      }
      
      const { url } = await urlResponse.json();
      
      setStatus('Uploading image...');
      const uploadResponse = await fetch(url, {
        method: 'PUT',
        headers: {
          'x-ms-blob-type': 'BlockBlob',
          'Content-Type': file.type,
          'x-ms-version': '2021-04-10',
          'Origin': window.location.origin
        },
        mode: 'cors',
        body: file
      });

      if (!uploadResponse.ok) {
        const errorText = await uploadResponse.text();
        throw new Error(`Upload failed: ${uploadResponse.status} ${errorText}`);
      }

      // Extract the base URL without SAS token
      const baseUrl = url.split('?')[0];
      setImageUrl(baseUrl);
      setInput('');
      setError('');
      setStatus('Image uploaded successfully');
    } catch (err) {
      console.error('Upload error:', err);
      setError(`Upload failed: ${err.message}`);
      setStatus('Error uploading image');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        console.log('Selected file:', file.name, file.type, file.size);
        await uploadToBlob(file);
      } else {
        setError('Please select an image file');
      }
    }
  };

  const startCamera = async () => {
    try {
      setShowCamera(true);
      setCameraError(null);
      
      // Wait for the next render cycle to ensure video element is mounted
      setTimeout(async () => {
        if (videoRef.current) {
          // Try to get the rear camera first on mobile devices
          const constraints = {
            video: {
              facingMode: 'environment',
              // Add iOS specific constraints
              width: { ideal: 1920 },
              height: { ideal: 1080 }
            }
          };

          try {
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            videoRef.current.srcObject = stream;
            streamRef.current = stream;
            
            // iOS Safari specific: wait for loadedmetadata event
            videoRef.current.onloadedmetadata = () => {
              // Update aspect ratio when video is loaded
              if (videoRef.current) {
              setVideoAspectRatio(
                  videoRef.current.videoWidth / videoRef.current.videoHeight
                );
              }

              videoRef.current.play()
                .then(() => {
                  setCameraInitialized(true);
                })
                .catch(error => {
                  console.error('Error playing video:', error);
                  setCameraError('Failed to start video preview');
                });
            };
          } catch (err) {
            // If environment camera fails, try any available camera
            if (err.name === 'OverconstrainedError' || err.name === 'ConstraintNotSatisfiedError') {
              const stream = await navigator.mediaDevices.getUserMedia({ video: true });
              videoRef.current.srcObject = stream;
              streamRef.current = stream;
              videoRef.current.onloadedmetadata = () => {
                videoRef.current.play()
                  .then(() => {
                    setCameraInitialized(true);
                  })
                  .catch(error => {
                    console.error('Error playing video:', error);
                    setCameraError('Failed to start video preview');
                  });
              };
            } else {
              throw err;
            }
          }
        }
      }, 100);
    } catch (err) {
      console.error('Camera error:', err);
      setCameraError(getErrorMessage(err));
      setShowCamera(false);
    }
  };

  const getErrorMessage = (error) => {
    switch (error.name) {
      case 'NotAllowedError':
        return 'Camera access was denied. Please allow camera access in your browser settings.';
      case 'NotFoundError':
        return 'No camera was found on your device.';
      case 'NotReadableError':
        return 'Camera is in use by another application.';
      case 'SecurityError':
        return 'Camera access is restricted. Please ensure you are using HTTPS.';
      default:
        return `Failed to access camera: ${error.message}`;
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null;
    }
    setShowCamera(false);
    setCameraInitialized(false);
    setCameraError(null);
  };

  const captureImage = async () => {
    if (!videoRef.current || !streamRef.current) {
      setError('Camera not initialized');
      return;
    }

    try {
      const canvas = document.createElement('canvas');
      const video = videoRef.current;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      
      const ctx = canvas.getContext('2d');
      ctx.drawImage(video, 0, 0);
      
      const blob = await new Promise((resolve) => {
        canvas.toBlob(resolve, 'image/jpeg', 0.8);
      });
      
      const file = new File([blob], 'camera-capture.jpg', { type: 'image/jpeg' });
      
      stopCamera();
      await uploadToBlob(file);
    } catch (err) {
      console.error('Capture error:', err);
      setError(`Failed to capture image: ${err.message}`);
    }
  };

  const generateBPMN = async () => {
    try {
      setLoading(true);
      setError('');
      setBpmnOutput('');
      setStatus('Starting BPMN generation...');

      const response = await fetch('/api/generate-bpmn', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          imageUrl: imageUrl || null,
          textInput: input || null,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to generate BPMN');
      }

      const data = await response.json();
      setBpmnOutput(data.bpmn);
      setShowCalmPrompt(true);
      setStatus('BPMN generated successfully!');
    } catch (err) {
      setError(`Error generating BPMN: ${err.message}`);
      setStatus('Error occurred during generation');
    } finally {
      setLoading(false);
    }
  };
  
  const sendToCalm = async () => {
    if (!diagramName) {
      setError('Please enter a diagram name');
      return;
    }

    try {
      setLoading(true);
      setStatus('Getting CALM token...');

      const tokenResponse = await fetch('/api/calm-token', {
        method: 'POST'
      });
      
      if (!tokenResponse.ok) throw new Error('Failed to get CALM token');
      const { access_token } = await tokenResponse.json();

      setStatus('Uploading to CALM...');
      const uploadResponse = await fetch('/api/calm-upload', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          bpmn: bpmnOutput,
          name: diagramName,
          token: access_token,
        }),
      });

      if (!uploadResponse.ok) throw new Error('Failed to upload to CALM');

      setStatus('Successfully uploaded to CALM!');
      setShowCalmPrompt(false);
    } catch (err) {
      setError(err.message);
      setStatus('Error uploading to CALM');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="max-w-4xl mx-auto p-4 space-y-6">
      {/* Your existing JSX remains exactly the same */}
      <div className="text-center space-y-4">
        <div className="flex justify-center">
          <img 
            src="/rapid-logo.png" 
            alt="Rapid" 
            className="h-12 mb-4"
          />
        </div>
        <h1 className="text-2xl font-bold mb-4 text-rapid-navy">BPMN Generator</h1>
        <p className="text-gray-600">Upload an image, take a photo, or describe your process</p>
      </div>

      {status && (
        <div className={`p-4 rounded ${
          error 
            ? 'bg-red-50 text-red-700' 
            : 'bg-rapid-blue/10 text-rapid-navy'
        } flex items-center`}>
          {loading && <Loader className="animate-spin mr-2 h-4 w-4" />}
          <span>{status}</span>
        </div>
      )}

      <div className="space-y-4">
      <div className="flex gap-4">
          <button
            onClick={() => fileInputRef.current?.click()}
            className="flex-1 py-2 px-4 rounded bg-rapid-navy text-white hover:bg-rapid-blue flex items-center justify-center"
            disabled={loading}
          >
            <Upload className="w-4 h-4 mr-2" />
            Upload Image
          </button>
          {isCameraSupported && (
            <button
              onClick={showCamera ? stopCamera : startCamera}
              className="flex-1 py-2 px-4 rounded bg-rapid-navy text-white hover:bg-rapid-blue flex items-center justify-center"
              disabled={loading}
            >
              <Camera className="w-4 h-4 mr-2" />
              {showCamera ? 'Stop Camera' : 'Take Photo'}
            </button>
          )}
        </div>
        
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          className="hidden"
          onChange={handleFileUpload}
        />

      {showCamera && (
        <div className="relative border rounded-lg overflow-hidden bg-gray-100">
          <div style={{ 
            position: 'relative',
            paddingTop: `${(1 / videoAspectRatio) * 100}%`, // Maintain aspect ratio
            width: '100%'
          }}>
            <video
              ref={videoRef}
              autoPlay
              playsInline
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover'
              }}
            />
            {cameraError ? (
              <div className="absolute top-0 left-0 right-0 bg-red-50 text-red-700 p-2 text-sm">
                {cameraError}
              </div>
            ) : cameraInitialized && (
              <div className="absolute bottom-0 left-0 right-0 p-4 bg-gradient-to-t from-black/50 to-transparent">
                <button
                  onClick={captureImage}
                  className="w-full max-w-xs mx-auto block py-3 px-4 rounded-full bg-white text-rapid-navy hover:bg-gray-100 font-medium shadow-lg"
                >
                  Capture
                </button>
              </div>
            )}
          </div>
        </div>
      )}

        <input
          type="url"
          className="w-full p-2 border rounded-lg focus:ring-rapid-blue focus:border-rapid-blue"
          placeholder="Or enter image URL..."
          onChange={handleImageUrlInput}
          value={imageUrl}
          disabled={loading}
        />

        {imageUrl && (
          <div className="mt-2 border rounded-lg p-2 bg-gray-50">
            <p className="text-sm text-gray-600 mb-2">Image Preview:</p>
            <img
              src={imageUrl}
              alt="Process"
              className="max-w-full h-auto rounded"
              onError={() => setError('Failed to load image')}
            />
          </div>
        )}

        <textarea
          className="w-full h-32 p-2 border rounded-lg focus:ring-rapid-blue focus:border-rapid-blue"
          placeholder="Or describe your process here..."
          onChange={handleTextInput}
          value={input}
          disabled={loading}
        />

        <button
          onClick={generateBPMN}
          disabled={loading || (!input && !imageUrl)}
          className="w-full py-2 px-4 rounded flex items-center justify-center text-white bg-rapid-navy hover:bg-rapid-blue disabled:bg-gray-400 disabled:cursor-not-allowed"
        >
          {loading && <Loader className="animate-spin mr-2 h-4 w-4" />}
          {loading ? 'Generating...' : 'Generate BPMN'}
        </button>
      </div>

      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded">
          {error}
        </div>
      )}

      {bpmnOutput && <BPMNPreview bpmnXml={bpmnOutput} />}

      {showCalmPrompt && (
        <div className="space-y-4">
          <input
            type="text"
            placeholder="Enter diagram name for CALM"
            className="w-full p-2 border rounded focus:ring-rapid-blue focus:border-rapid-blue"
            value={diagramName}
            onChange={(e) => setDiagramName(e.target.value)}
          />
          <div className="flex space-x-4">
            <button
              onClick={sendToCalm}
              disabled={loading}
              className="flex-1 py-2 px-4 rounded bg-rapid-navy text-white hover:bg-rapid-blue disabled:bg-gray-400"
            >
              {loading ? 'Sending...' : 'Send to CALM'}
            </button>
            <button
              onClick={() => setShowCalmPrompt(false)}
              disabled={loading}
              className="flex-1 py-2 px-4 rounded bg-gray-600 text-white hover:bg-gray-700 disabled:bg-gray-400"
            >
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default BPMNGenerator;