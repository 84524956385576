import React, { useState, useEffect, useRef } from 'react';
import { Code, Eye } from 'lucide-react';
import BpmnViewer from 'bpmn-js/lib/Viewer';
import 'bpmn-js/dist/assets/diagram-js.css';
import 'bpmn-js/dist/assets/bpmn-font/css/bpmn.css';
import '../styles/bpmn.css';

const BPMNPreview = ({ bpmnXml }) => {
  const [viewMode, setViewMode] = useState('diagram');
  const [error, setError] = useState(null);
  const viewerRef = useRef(null);
  const containerRef = useRef(null);

  const initializeDiagram = async () => {
    if (!containerRef.current || !bpmnXml) return;

    try {
      // Clean up existing viewer
      if (viewerRef.current) {
        viewerRef.current.destroy();
        viewerRef.current = null;
      }

      // Clear the container
      containerRef.current.innerHTML = '';

      // Create new viewer
      const viewer = new BpmnViewer();
      viewerRef.current = viewer;

      // Attach viewer to container
      viewer.attachTo(containerRef.current);

      // Import XML
      await viewer.importXML(bpmnXml);
      
      // Ensure container has dimensions
      if (containerRef.current.clientWidth > 0) {
        const canvas = viewer.get('canvas');
        canvas.zoom('fit-viewport', 'auto');
      }

      setError(null);
    } catch (err) {
      console.error('BPMN viewer error:', err);
      setError('Failed to render diagram');
    }
  };

  useEffect(() => {
    if (viewMode === 'diagram') {
      // Use a small delay to ensure container is ready
      const timer = setTimeout(() => {
        initializeDiagram();
      }, 100);

      return () => {
        clearTimeout(timer);
        if (viewerRef.current) {
          try {
            viewerRef.current.destroy();
            viewerRef.current = null;
          } catch (err) {
            console.error('Cleanup error:', err);
          }
        }
      };
    }
  }, [viewMode, bpmnXml]);

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center">
        <h2 className="text-lg font-semibold text-rapid-navy">BPMN Preview</h2>
        <button
          onClick={() => {
            if (viewerRef.current) {
              viewerRef.current.destroy();
              viewerRef.current = null;
            }
            setViewMode(current => current === 'xml' ? 'diagram' : 'xml');
          }}
          className="flex items-center px-3 py-1 rounded bg-rapid-blue/10 hover:bg-rapid-blue/20 text-rapid-navy"
        >
          {viewMode === 'xml' ? (
            <>
              <Eye className="w-4 h-4 mr-2" />
              Show Diagram
            </>
          ) : (
            <>
              <Code className="w-4 h-4 mr-2" />
              Show XML
            </>
          )}
        </button>
      </div>

      {viewMode === 'xml' ? (
        <div className="bg-gray-100 p-4 rounded-lg overflow-x-auto">
          <pre className="text-xs">{bpmnXml}</pre>
        </div>
      ) : (
        <div className="relative">
          <div 
            ref={containerRef}
            style={{
              height: '600px',
              width: '100%',
              position: 'relative'
            }}
            className="border rounded-lg bg-white"
          />
          {error && (
            <div className="absolute top-0 left-0 right-0 bg-red-50 text-red-700 p-2 rounded-t-lg">
              {error}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BPMNPreview;