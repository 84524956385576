import React from 'react';
import BPMNGenerator from './components/BPMNGenerator';
import './App.css';

function App() {
  return (
    <div className="App">
      <BPMNGenerator />
    </div>
  );
}

export default App;